import { ADD_BOOKMARK } from '../constants'

const initialState = {
  flag: false,
  bookmarkItems: [],
}

export default function bookmark(state = initialState, action) {
  switch (action.type) {
    case ADD_BOOKMARK: {
      return { ...state, flag: action.flag }
    }

    default: {
      return state
    }
  }
}
