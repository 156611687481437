import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { FirebaseAppProvider } from 'reactfire'
import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { RecoilRoot } from 'recoil'
import { Provider as UrqlProvider } from 'urql'
import AppDrawer from '@/containers/organisms/AppDrawer/AppDrawer'
import { NextSeo } from '@/components/NextSeo'
import { useApollo } from '@/graphql/apolloClient'
import { useUrqlClient } from '@/graphql/urqlClient'
import { useSessionLogin } from '@/hooks/useSessionLogin'
import { defaultTheme } from '@/styles/defaultTheme'
import { isBrowserDetect } from '@/lib/isBrowserDetect'
import { isMobileDetect } from '@/lib/isMobileDetect'
import firebaseConfig from '../firebase/firebaseConfig'
import { useStore } from '../store/configureStore'

type Props = {
  Component: AppProps['Component']
  pageProps: AppProps['pageProps']
  asPath: AppProps['router']['asPath']
  user: any
}

const DynamicFirebaseAppIndividualProviders = dynamic(
  () => import('../components/common/FirebaseAppIndividualProviders'),
  { ssr: false },
)

const DynamicSentryUser = dynamic(() => import('../components/common/SentryUser'))

const DynamicMyPageViewLogger = dynamic(() => import('../components/common/MyPageViewLogger'))

function InitEnvironment() {
  const router = useRouter()
  const domain = process.env.NEXT_PUBLIC_PC_HOST_URL
  const isBrowser = isBrowserDetect()

  useEffect(() => {
    if (window?.navigator && isBrowser) {
      const userAgent = window?.navigator.userAgent
      const isMobile = isMobileDetect(userAgent)
      if (!isMobile && domain) {
        window.location.href = `${domain}${router.asPath}`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, isBrowser])

  return null
}

function SessionLogin() {
  useSessionLogin()

  return null
}

export function MyApp(props: Props) {
  const client = useUrqlClient()
  const apolloClient = useApollo(props.pageProps.initialApolloState)
  const store = useStore(props.pageProps.initialReduxState)
  const [state, setState] = useState(store.getState())

  const updateState = () => {
    setState(store.getState())
  }
  store.subscribe(updateState)

  useEffect(() => {
    TagManager.initialize({ gtmId: `${process.env.NEXT_PUBLIC_GTM_ID}` })
  }, [])

  return (
    <>
      <NextSeo
        title={props.pageProps?.nextSeo?.title}
        description={props.pageProps?.nextSeo?.description}
        url={props.pageProps?.nextSeo?.url}
        canonical={props.pageProps?.nextSeo?.canonical}
        image={props.pageProps?.nextSeo?.image}
      />
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <DynamicFirebaseAppIndividualProviders>
          <RecoilRoot>
            <StyledEngineProvider injectFirst>
              <AppCacheProvider {...props}>
                <ThemeProvider theme={defaultTheme}>
                  <UrqlProvider value={client()}>
                    <ApolloProvider client={apolloClient}>
                      <CssBaseline />
                      <Provider store={store}>
                        <SessionLogin />
                        <div>
                          <AppDrawer asPath={props.asPath}>
                            <props.Component {...props.pageProps} />
                            <InitEnvironment />
                          </AppDrawer>
                        </div>
                      </Provider>
                    </ApolloProvider>
                  </UrqlProvider>
                </ThemeProvider>
              </AppCacheProvider>
            </StyledEngineProvider>
            {isBrowserDetect() && <DynamicMyPageViewLogger user={props.user} />}
            <DynamicSentryUser user={state?.user?.user_id} />
          </RecoilRoot>
        </DynamicFirebaseAppIndividualProviders>
      </FirebaseAppProvider>
    </>
  )
}

export default MyApp
