import { DETAILS_SUCCESS, GET_RESIN_LIST, DETAILS_ERROR } from '../constants'

const initialState = {
  isEmpty: false,
  isfromCore: false,
  details_loading: false,
  makerList: [],
  resinLists: [],
  resinName: [],
  product_list: [],
  resin_list: [],
  maker_list: [],
}

export default function details(state = initialState, action) {
  switch (action.type) {
    case DETAILS_SUCCESS: {
      return {
        ...state,
        itemDetails: action.itemDetails,
        details_loading: false,
        isEmpty: false,
      }
    }

    case DETAILS_ERROR: {
      return { ...state, isEmpty: true }
    }

    case GET_RESIN_LIST: {
      return {
        ...state, // resinLists: action.resinLists
        resinLists: action.resinLists.resinLists,
        resinObjectCount: action.resinLists.resinObjectCount,
        reqResin: action.resinLists.reqResin,
        product_list: action.resinLists.productList,
        maker_list: action.resinLists.makerList,
        resinName: action.resinLists.resinNames,
      }
    }

    default: {
      return state
    }
  }
}
