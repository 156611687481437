import * as Sentry from '@sentry/nextjs'
import { AxiosError, isCancel, isAxiosError } from 'axios'

let isSentryEnabled = false

const SENTRY_DSN = process?.env?.NEXT_PUBLIC_SENTRY_DSN
const NODE_ENV = process?.env?.NODE_ENV

type AxiosConfig = {
  configUrl?: string
  configMethod?: string
  configBaseURL?: string
  configParams?: string
  configData?: string
  configResponseType?: string
}

export function setupSentry() {
  if (NODE_ENV !== 'development' && SENTRY_DSN) {
    isSentryEnabled = true
    Sentry.init({
      dsn: SENTRY_DSN,
      normalizeDepth: 10,
    })
  }
}

// info containing the extra information you want to attach to the current scope
type Info = {
  title?: string
  description?: string
}

export function errorLog(err: unknown, info?: Info, isSkippedSentry?: boolean) {
  if (!isSentryEnabled || isSkippedSentry) {
    /* eslint-disable no-console */
    console.error(err)

    return
  }

  if (isCancel(err)) return

  if (isAxiosError(err)) {
    const error = err as AxiosError
    const endpoint = error.response?.config?.url || ''
    const status = error.response?.status
    const method = error.response?.config?.method || ''

    const configs: AxiosConfig = {}

    if (error.config?.url) {
      configs.configUrl = error.config.url
    }

    if (error.config?.method) {
      configs.configMethod = error.config.method
    }

    if (error.config?.baseURL) {
      configs.configBaseURL = error.config.baseURL
    }

    if (error.config?.params) {
      configs.configParams = JSON.stringify(error.config.params)
    }

    if (error.config?.data) {
      configs.configData = JSON.stringify(error.config.data)
    }

    if (error.config?.responseType) {
      configs.configResponseType = error.config.responseType
    }

    Sentry.withScope((scope) => {
      scope.setFingerprint(['{{ default }}', endpoint, String(status), method])
      scope.setTags(configs)

      if (info) {
        scope.setExtras(info)
      }

      Sentry.captureException(err)
    })
  } else {
    Sentry.withScope((scope) => {
      if (info) {
        scope.setExtras(info)
      }

      Sentry.captureException(err)
    })
  }
}
