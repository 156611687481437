import {
  SETTINGS_SUCCESS,
  GET_FLAG_SUCCESS,
  CHECK_SUCCESS,
  SAVE_COLUMN_SUCCESS,
  GET_SAVED_SUCCESS,
  GET_ONE_SAVE,
  DEFAULT_FLAG,
  REVERT_FLAG,
  INIT_FLAG,
  GET_ONE_PRESET,
} from '../constants'

const initialState = {
  flag: {
    maker: {
      flag: true,
      sort: 1,
    },
    resin_name: {
      flag: true,
      sort: 3,
    },
    product_name: {
      flag: true,
      sort: 4,
    },
    iso_name: {
      flag: true,
      sort: 5,
    },
    segment: {
      flag: false,
      sort: 7,
    },
    color: {
      flag: false,
      sort: 8,
    },
    characteristic: {
      flag: false,
      sort: 9,
    },
    usage: {
      flag: false,
      sort: 10,
    },
    A0: {
      flag: true,
      sort: 15,
    },
    A1: {
      flag: true,
      sort: 16,
    },
    B0: {
      flag: false,
      sort: 21,
    },
    B1: {
      flag: false,
      sort: 22,
    },
    B3: {
      flag: true,
      sort: 24,
    },
    B6: {
      flag: true,
      sort: 27,
    },
    B7: {
      flag: true,
      sort: 28,
    },
    B8: {
      flag: true,
      sort: 29,
    },
    B9: {
      flag: false,
      sort: 30,
    },
    B11: {
      flag: false,
      sort: 32,
    },
    B12: {
      flag: true,
      sort: 64,
    },
    B14: {
      flag: false,
      sort: 101,
    },
    B15: {
      flag: false,
      sort: 104,
    },
    C1: {
      flag: true,
      sort: 34,
    },
    C2: {
      flag: false,
      sort: 35,
    },
    C3: {
      flag: false,
      sort: 36,
    },
    C4: {
      flag: false,
      sort: 37,
    },
    C5: {
      flag: false,
      sort: 38,
    },
    C6: {
      flag: false,
      sort: 39,
    },
    C7: {
      flag: true,
      sort: 40,
    },
    C10: {
      flag: false,
      sort: 43,
    },
    C11: {
      flag: false,
      sort: 44,
    },
    C12: {
      flag: true,
      sort: 45,
    },
    C13: {
      flag: true,
      sort: 46,
    },
    C14: {
      flag: false,
      sort: 47,
    },
    C17: {
      flag: false,
      sort: 50,
    },
    C18: {
      flag: false,
      sort: 51,
    },
    C20: {
      flag: false,
      sort: 53,
    },
    D0: {
      flag: false,
      sort: 55,
    },
    D1: {
      flag: false,
      sort: 56,
    },
    D2: {
      flag: false,
      sort: 57,
    },
    D3: {
      flag: false,
      sort: 58,
    },
    D4: {
      flag: false,
      sort: 59,
    },
    D5: {
      flag: false,
      sort: 60,
    },
    D7: {
      flag: false,
      sort: 61,
    },
    E0: {
      flag: false,
      sort: 64,
    },
    E1: {
      flag: false,
      sort: 65,
    },
    E2: {
      flag: false,
      sort: 107,
    },
    H0: {
      flag: false,
      sort: 75,
    },
    H1: {
      flag: false,
      sort: 76,
    },
    H2: {
      flag: false,
      sort: 77,
    },
    H3: {
      flag: false,
      sort: 78,
    },
    H4: {
      flag: false,
      sort: 79,
    },
    I0: {
      flag: false,
      sort: 81,
    },
    I1: {
      flag: false,
      sort: 82,
    },
    I6: {
      flag: false,
      sort: 110,
    },
    J0: {
      flag: false,
      sort: 86,
    },
    notes: {
      flag: false,
      sort: 95,
    },
  },
  originalFlag: null,
  label_units: {
    A0: 'g/cm3',
    A1: '%',
    B1: '℃',
    B3: '℃',
    B6: 'x10^-5/℃',
    B7: 'x10^-5/℃',
    B11: '℃',
    B14: '-',
    B15: '-',
    C1: 'MPA',
    C2: 'MPA',
    C3: '%',
    C4: 'MPA',
    C6: 'MPA',
    C7: 'MPA',
    C11: '%',
    C12: 'kJ/m^2',
    C13: 'KJ/m^2',
    C14: '-',
    D0: 'Ω・m',
    D1: 'Ω',
    D2: '-',
    D3: '-',
    D4: 'MV/m ',
    D5: 'sec',
    D6: 'Ω',
    E1: '%',
    E2: '-',
    H0: '%',
    H1: '%',
    H2: '%',
    H3: 'g/10min',
    H4: 'cm3/10min',
    I6: '-',
    J0: '%',
  },
  saved_table: [],
}

export default function customize_columns(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_SUCCESS: {
      return { ...state, originalFlag: JSON.parse(JSON.stringify(state.flag)) }
    }

    case SAVE_COLUMN_SUCCESS: {
      return state
    }

    case INIT_FLAG: {
      return { ...state, originalFlag: JSON.parse(JSON.stringify(state.flag)) }
    }

    case GET_FLAG_SUCCESS: {
      if (action.flag != null) {
        return {
          ...state,
          flag: action.flag,
          originalFlag: JSON.parse(JSON.stringify(action.flag)),
        }
      }

      return { ...state, originalFlag: JSON.parse(JSON.stringify(state.flag)) }
    }

    case CHECK_SUCCESS: {
      return { ...state, flag: action.flag }
    }

    case GET_SAVED_SUCCESS: {
      return { ...state, saved_table: action.saved_table }
    }

    /* eslint-disable no-underscore-dangle */
    case GET_ONE_SAVE: {
      for (const savedTableItem of state.saved_table) {
        if (savedTableItem._id === action.id) {
          const flag = { ...savedTableItem }

          return { ...state, flag }
        }
      }

      return state
    }
    /* eslint-enable no-underscore-dangle */

    case DEFAULT_FLAG: {
      return { ...state, flag: JSON.parse(JSON.stringify(initialState.flag)) }
    }

    case GET_ONE_PRESET: {
      return { ...state, flag: action.flags }
    }

    case REVERT_FLAG: {
      if (state.originalFlag != null) {
        return {
          ...state,
          flag: JSON.parse(JSON.stringify(state.originalFlag)),
        }
      }

      return state
    }

    default: {
      return state
    }
  }
}
