import api from '@/api/content'
import { SWITCH_LANGUEAGE } from '../constants'

const initialState = {
  language: api.getContent(),
  languageName: null,
}

export default function language(state = initialState, action) {
  switch (action.type) {
    case SWITCH_LANGUEAGE: {
      return {
        ...state,
        languageName: action.language,
        language: api.getContent(action.language),
      }
    }

    default: {
      return state
    }
  }
}
