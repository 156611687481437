import {
  START_SEARCH_LOADING_ANIMATION,
  END_SEARCH_LOADING_ANIMATION,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
  SEARCH_ITEM_ESTIMATE_NUMBER,
  VISIT_WAY,
  INITIAL_ITEM_COUNT,
  SAVE_SEARCH_SUCCESS,
  SAVE_USER_SEARCH_SUCCESS,
  GET_USER_SEARCH_SUCCESS,
  GET_ONE_SEARCH,
  CHANGED_SLIDER,
  GET_FULLTEXT_SUCCESS,
  GET_MINMAX_SUCCESS,
  CHANGED_MINMAX,
  SLIDER_CHECK_SUCCESS,
  RESET_SEARCH_ITEMS,
  SET_SELECT_ROW_KEYS,
  RESET_SELECTED_ROW_KEYS,
  SIMILAR_SEARCH_CHANGED_SLIDER,
  SIMILAR_SEARCH_CHANGED_SLIDER_CHECKED,
  BACKUP_SEARCH_QUERY,
  BACKUP_ITEM_PROPERTY,
  RESET_SAVED_SEARCH,
} from '../constants'

export const initialState = {
  data: [],
  start: 1,
  query: {
    maker_names: [],
    resin_names: [],
    iso_names: [],
    product_names: [],
    flame_ratings: [],
    tags: [],
    standards: {},
    fullText: '',
    active: 0,
    includeNull: 0,
    isFullItems: false,
    page: 0,
    items: [],
    itemCount: 0,
    language: '',
    sortName: '',
    sortOrder: '',
    start: 0,
    user: '',
    is_count: false,
    type: '',
    checkbox: {},
    min_max: {},
  },
  loading: false,
  home_loading: false,
  saved_search: [],
  saved_user_search: [],
  fulltexts: [],
  tags: [],
  checkbox: {
    A0: false,
    A1: false,
    B1: false,
    B3: false,
    B5: false,
    B6: false,
    B7: false,
    B11: false,
    B14: false,
    B15: false,
    C0: false,
    C1: false,
    C2: false,
    C4: false,
    C6: false,
    C7: false,
    C11: false,
    C12: false,
    C13: false,
    C14: false,
    C16: false,
    D2: false,
    D3: false,
    D4: false,
    D5: false,
    E1: false,
    E2: false,
    H2: false,
    H3: false,
    H4: false,
    H5: false,
    I6: false,
  },
  selectedRowKeys: [],
  min_max: null,
  item_count: null,
  initial_item_count: 6152,
  backupQuery: {},
  backupCheckbox: {},
  visitWay: 'direct',
  saveSearchQueryId: null,
}

export default function search(state = initialState, action) {
  switch (action.type) {
    case START_SEARCH_LOADING_ANIMATION: {
      return { ...state, loading: true }
    }

    case END_SEARCH_LOADING_ANIMATION: {
      return { ...state, loading: false }
    }

    case SEARCH_SUCCESS: {
      return { ...state, loading: false, start: action.page, data: action.data }
    }

    case SEARCH_ERROR: {
      return { ...state, loading: false, home_loading: false }
    }

    case SLIDER_CHECK_SUCCESS: {
      return {
        ...state,
        checkbox: action.slider,
        backupCheckbox: action.slider,
      }
    }

    case RESET_SAVED_SEARCH: {
      return {
        ...state,
        query: {
          maker_names: [],
          resin_names: [],
          iso_names: [],
          product_names: [],
          flame_ratings: [],
          tags: [],
          standards: {},
          fullText: '',
          active: 0,
          includeNull: 0,
          isFullItems: true,
          items: [],
          itemCount: 0,
          language: '',
          sortName: '',
          sortOrder: '',
          start: 0,
          user: '',
          is_count: false,
          type: '',
          min_max: {},
        },

        checkbox: {
          A0: false,
          A1: false,
          B1: false,
          B3: false,
          B6: false,
          B7: false,
          C1: false,
          C2: false,
          C4: false,
          C6: false,
          C7: false,
          C11: false,
          C12: false,
          C13: false,
          C14: false,
          D2: false,
          D3: false,
          D4: false,
          D5: false,
          E1: false,
          H2: false,
          H3: false,
          H4: false,
        },
        saveName: undefined,
      }
    }

    case SEARCH_ITEM_ESTIMATE_NUMBER: {
      return { ...state, item_count: action.item_count }
    }

    case INITIAL_ITEM_COUNT:
      return { ...state, initial_item_count: action.item_count }

    case SAVE_SEARCH_SUCCESS: {
      return {
        ...state,
        saveSearchQueryId: action.saveSearchQueryId,
        visitWay: 'search',
      }
    }

    case VISIT_WAY: {
      return { ...state, visitWay: action.visitWay }
    }

    case SAVE_USER_SEARCH_SUCCESS: {
      return state
    }

    case GET_USER_SEARCH_SUCCESS: {
      return { ...state, saved_user_search: action.saved_user_search }
    }

    case RESET_SEARCH_ITEMS: {
      return {
        ...state,
        query: action.data.query,
        checkbox: action.data.checkbox,
      }
    }

    /* eslint-disable no-underscore-dangle */
    case GET_ONE_SEARCH: {
      const query = { ...action.item }
      const { checkbox } = query
      const { saveName } = query

      delete query._id
      delete query.saveName
      delete query.created_time
      delete query.checkbox

      return { ...state, query, checkbox, saveName }
    }

    case CHANGED_SLIDER: {
      return { ...state, query: action.query, backupQuery: action.query }
    }

    case GET_FULLTEXT_SUCCESS: {
      return { ...state, fulltexts: action.fulltexts }
    }

    case GET_MINMAX_SUCCESS: {
      const query = { ...state.query, ...action.min_max }
      delete query._id
      delete query.stds

      return { ...state, min_max: action.min_max }
    }
    /* eslint-enable no-underscore-dangle */

    case CHANGED_MINMAX: {
      return { ...state, min_max: action.min_max }
    }

    case SET_SELECT_ROW_KEYS: {
      return { ...state, selectedRowKeys: action.rowKeys }
    }

    case RESET_SELECTED_ROW_KEYS: {
      return { ...state, selectedRowKeys: [] }
    }

    case SIMILAR_SEARCH_CHANGED_SLIDER: {
      return { ...state, query: action.query }
    }

    case SIMILAR_SEARCH_CHANGED_SLIDER_CHECKED: {
      return { ...state, checkbox: action.checkbox }
    }

    case BACKUP_SEARCH_QUERY: {
      const backupQuery = { ...state.query }
      const backupCheckbox = { ...state.checkbox }

      return {
        ...state,
        backupCheckbox,
        backupQuery,
      }
    }

    case BACKUP_ITEM_PROPERTY: {
      return { ...state, checkbox: action.backupCheckbox }
    }

    default: {
      return state
    }
  }
}
