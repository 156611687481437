import { RECOMMEND_SUCCESS } from '../constants'

const initialState = {}

export default function recommend(state = initialState, action) {
  switch (action.type) {
    case RECOMMEND_SUCCESS: {
      const maker_id = {}
      const resin_name = {}
      maker_id[action.maker_id] = action.recommendResult.maker
      resin_name[action.resin_name] = action.recommendResult.resin

      return { ...state, ...maker_id, ...resin_name }
    }

    default: {
      return state
    }
  }
}
