const code = {
  COULD_NOT_SEND_EMAIL: 'sample/could-not-send-email',
  VALIDATION_FAILED: 'validate/validation-failed',
  PASSWORD_INCORRECT: 'user/password-incorrect',
  RESET_PASSWORD_FAILURE: 'user/reset-password-failure',
  TOO_MANY_REQUESTS: 'user/too-many-requests',
  EMAIL_DOES_NOT_EXIST: 'user/email-does-not-exist',
  FORGOT_PASSWORD_EMAIL_SEND: 'user/forgot-password-email-send',
  FORGOT_PASSWORD_RESET_FAILED: 'user/forgot-password-reset-failed',
  SHOW_RESET_PASSWORD_FAILED: 'user/show-reset-password-failed',
  USER_KEY_DOES_NOT_EXIST: 'user/user-key-does-not-exist',
  USER_DOES_NOT_EXIST: 'user/user-does-not-exist',
  PASSWORD_COUNT_ERROR: 'user/password-count-error',
  EXISTING_USER: 'user/existing-user',
  ERROR_LOGIN: 'user/error-login',
  EMAIL_ERROR: 'user/email-error',
  SIGNUP_ERROR: 'user/signup-error',
  COULD_NOT_SAVE_NEW_PASSWORD: 'user/could-not-save-new-password',
  FETCH_PASSPORT_USER_FAILED: 'user/fetch-passport-user-failed',
  NAME_AND_PASSWORD_NOT_MATCH: 'user/user-name-and-password-does-not-match',
  USER_NOT_ALLOWED: 'user/user-not-allowed',
  UNABLE_TO_LOGIN: 'user/unable-to-login',
  FAILED_TO_UPDATE_USER_ACCOUNT_INFO: 'user/failed-to-update-user-account-information',
  NO_PERMISSION_TO_DELETE_ACCOUNT: 'user/no-permission-to-delete-account',
  FAILED_TO_DELETE_ACCOUNT: 'user/failed-to-delete-account',
  ERROR_IN_ACCOUNT_DELETION: 'user/error-in-account-deletion',
  RELATED_RESIN_FETCH_ERROR: 'user/related-resin-fetch-error',
  WORDPRESS_FAVOURITE_FETCH_ERROR: 'user/wordpress-favorite-fetch-error',
  WORDPRESS_FAVOURITE_CREATION_ERROR: 'user/wordpress-favorite-creation-error',
  FAILED_TO_UPDATE_SEARCH_SETTINGS: 'user/failed-to-update-search-settings',
  LOGOUT_ERROR: 'user/logout-error',
  PERSONAL_INFORMATION_UPDATE_FAILED: 'user/personal-information-update-failed',
}
const message = {
  [code.COULD_NOT_SEND_EMAIL]:
    '送信エラーになりました。大変申し訳ございませんが、再度お問い合わせください。',
  [code.VALIDATION_FAILED]: '入力内容に誤りがあります。',
  [code.PASSWORD_INCORRECT]: '正しいパスワードを入力してください',
  [code.RESET_PASSWORD_FAILURE]: '正しいパスワードを入力してください',
  [code.TOO_MANY_REQUESTS]:
    '入力回数の上限を超えたため、ご利用いただけません。数時間後にもう一度お試しください',
  [code.EMAIL_DOES_NOT_EXIST]: 'ご入力されたメールアドレスのアカウントは存在しません',
  [code.FORGOT_PASSWORD_EMAIL_SEND]: 'Forgot password email send',
  [code.FORGOT_PASSWORD_RESET_FAILED]: 'Forgot password reset failed',
  [code.SHOW_RESET_PASSWORD_FAILED]: 'パスワードのリセットに失敗しました',
  [code.USER_KEY_DOES_NOT_EXIST]: 'ユーザーキーが存在しません',
  [code.USER_DOES_NOT_EXIST]: 'ユーザーは存在しません',
  [code.PASSWORD_COUNT_ERROR]: 'Password count error',
  [code.EXISTING_USER]: 'ご入力いただいたメールアドレスが既に他のアカウントで登録されております。',
  [code.ERROR_LOGIN]: '登録できませんでした',
  [code.EMAIL_ERROR]: 'Email error',
  [code.SIGNUP_ERROR]: 'Signup error',
  [code.COULD_NOT_SAVE_NEW_PASSWORD]: 'Could not save new password',
  [code.FETCH_PASSPORT_USER_FAILED]: 'Fech passport user failed',
  [code.NAME_AND_PASSWORD_NOT_MATCH]:
    'ご入力されたメールアドレスまたはパスワードは正しくありません。',
  [code.USER_NOT_ALLOWED]: 'User not allowed',
  [code.UNABLE_TO_LOGIN]: 'ログインできませんでした',
  [code.FAILED_TO_UPDATE_USER_ACCOUNT_INFO]: '設定を保存できませんでした。',
  [code.NO_PERMISSION_TO_DELETE_ACCOUNT]: 'No permission to delete account',
  [code.FAILED_TO_DELETE_ACCOUNT]: 'アカウントの削除ができませんでした',
  [code.ERROR_IN_ACCOUNT_DELETION]: 'Error in account deletion',
  [code.RELATED_RESIN_FETCH_ERROR]: '関連する樹脂フェッチエラー',
  [code.WORDPRESS_FAVOURITE_FETCH_ERROR]: 'ワードプレスのお気に入りの取得エラー',
  [code.WORDPRESS_FAVOURITE_CREATION_ERROR]:
    'ワードプレスのお気に入りの作成中にエラーが発生しました',
  [code.FAILED_TO_UPDATE_SEARCH_SETTINGS]: 'Failed to update search settings',
  [code.LOGOUT_ERROR]: 'Logout error',
  [code.PERSONAL_INFORMATION_UPDATE_FAILED]: 'Personal information update failed',
}

const errTypes = { code, message }
module.exports = errTypes
