import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { handleMessageOpen } from '@/actions/snackbar'
import { sessionSuccess, notLoginUser } from '@/actions/user'
import { fetchUser } from '@/api/fetchUser'
import { errorLog } from '@/lib/errorLog'
import { fetcher } from '@/lib/fetcher'

export const useSessionLogin = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    fetcher
      .post('/v1/user/session', {})
      .then(({ data }) => {
        if (data.user) {
          const userData = fetchUser(data.user)
          dispatch(sessionSuccess(userData))
        } else {
          dispatch(notLoginUser())
        }
      })
      .catch((err) => {
        errorLog(err)
        dispatch(handleMessageOpen('エラーが発生しました', 'error', 5000))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
