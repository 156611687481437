export function existPersonalInformation(userData) {
  const { address, department, business, postcode, created_time } = userData
  if (
    typeof address !== 'undefined' &&
    address !== '' &&
    typeof department !== 'undefined' &&
    department !== '' &&
    typeof business !== 'undefined' &&
    business !== '' &&
    typeof postcode !== 'undefined' &&
    postcode !== ''
  ) {
    return true
  }
  if (created_time) {
    const createdTime = new Date(created_time)
    const currentTime = new Date()
    if (currentTime.getTime() - createdTime.getTime() < 14 * 24 * 3600 * 1000) {
      return true
    }
  }

  return false
}
