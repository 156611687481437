import axios from 'axios'
import Cookies from 'js-cookie'

export const fetcher = {
  get: async (path: string, data: any) => {
    return axios.get(`${process.env.NEXT_PUBLIC_API_SERVER_URL}${path}`, {
      params: data,
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
  },
  post: async (path: string, data: any) => {
    return axios.post(`${process.env.NEXT_PUBLIC_API_SERVER_URL}${path}`, data, {
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
  },
} as const
