import React, { FC } from 'react'
import { NextSeo as NextSeoMeta } from 'next-seo'

type Props = {
  title: string | undefined
  description: string | undefined
  canonical: string | undefined
  url: string | undefined
  image: string | undefined
}

export const NextSeo: FC<Props> = ({
  title = 'プラスチック・樹脂の成形材料の統合検索DB【PlaBase（プラベース）】',
  description = 'PlaBase（プラベース）】は樹脂（プラスチック）成形材料の統合検索サービスです。メーカー・樹脂名・物性値など多様な検索方法によって、お客様の目的に合った樹脂成形材料のデータを無料+B21:F32探し出すことができます。',
  canonical = undefined,
  url = undefined,
  image = 'https://s3-ap-northeast-1.amazonaws.com/plabase/public/ogp.jpg',
}) => (
  <NextSeoMeta
    title={title}
    description={description}
    canonical={canonical}
    openGraph={{
      url,
      title,
      description,
      images: [{ url: image }],
    }}
    facebook={{
      appId: '286780971447089',
    }}
  />
)
