import { ADD_WORDPRESS_FAVORITE } from '../../constants'

const initialState = {
  flag: false,
}

export default function favorite(state = initialState, action) {
  switch (action.type) {
    case ADD_WORDPRESS_FAVORITE: {
      return { ...state, flag: action.flag }
    }

    default: {
      return state
    }
  }
}
