import {
  LOGIN_SUCCESS_USER,
  LOGIN_ERROR_USER,
  SIGNUP_SUCCESS_USER,
  LOGOUT_USER,
  ACCOUNT_DELETE,
  ACCOUNT_DELETE_ERROR,
  ACCOUNT_DELETE_RESET,
  PASSWORD_CURRECT,
  LOGOUT_SUCCESS_USER,
  USER_LOGIN_MODE,
  USER_SIGNUP_MODE,
  LOGOUT_ERROR_USER,
  SESSION_SUCCESS_USER,
  OPEN_MODAL_LOGIN,
  CLOSE_MODAL_LOGIN,
  UPDATE_ACCOUNT_INFO_SUCCESS,
  UPDATE_ACCOUNT_INFO_ERROR,
  OPEN_MODAL_PASSWORD,
  PERSONAL_INFORMATION_SUCCESS,
  FORGET_PASSWORD_RESET,
  CHANGE_PASSWORD_RESET,
} from '../constants'

export default function user(
  state = {
    showLoginPage: true,
    message: '',
    isWaiting: true,
    user_id: -1,
    errormessage: '',
    successMessage: '',
    errorMessage: '',

    isPasswordcheck: false,
    isPasswordError: false,
    isPasswordSuccess: false,
    isKeySuccess: false,
    isEmailSuccess: false,
    isEmailError: false,

    authenticated: false,
    modalIsOpenLogin: false,
    existPersonalInformation: false,
  },
  action = {},
) {
  switch (action.type) {
    case LOGIN_SUCCESS_USER: {
      return {
        ...state,
        isWaiting: false,
        authenticated: true,
        message: '',
        user_info: action.userData,
        user_id: action.userData.user_id,
        existPersonalInformation: action.existPersonalInformation,
      }
    }

    case LOGIN_ERROR_USER: {
      return {
        ...state,
        isWaiting: false,
        authenticated: false,
        message: action.message,
      }
    }

    case SIGNUP_SUCCESS_USER: {
      return {
        ...state,
        isWaiting: false,
        authenticated: true,
        user_info: action.userData,
        user_id: action.userData.user_id,
        existPersonalInformation: action.existPersonalInformation,
      }
    }

    case ACCOUNT_DELETE: {
      return { ...state, isPasswordcheck: true, message: '' }
    }

    case ACCOUNT_DELETE_ERROR: {
      return {
        ...state,
        isPasswordcheck: false,
        isPasswordError: true,
        errormessage: 'Password is incorrect',
      }
    }

    case PASSWORD_CURRECT: {
      return {
        ...state,
        isPasswordSuccess: true,
        isPasswordError: false,
        errorMessage: '',
        successMessage: 'Password successfully changed',
      }
    }

    case OPEN_MODAL_PASSWORD: {
      return {
        ...state,
        isPasswordSuccess: false,
        isPasswordError: false,
        errorMessage: '',
      }
    }

    case ACCOUNT_DELETE_RESET: {
      return {
        ...state,
        isPasswordcheck: false,
        isPasswordError: false,
        errorMessage: '',
        successMessage: '',
      }
    }

    case FORGET_PASSWORD_RESET: {
      return { ...state, isEmailError: false, isEmailSuccess: false }
    }

    case CHANGE_PASSWORD_RESET: {
      return { ...state, isPasswordError: false, isPasswordSuccess: false }
    }

    case LOGOUT_USER: {
      return { ...state, isWaiting: true, message: '' }
    }

    case LOGOUT_SUCCESS_USER: {
      return {
        ...state,
        isWaiting: false,
        authenticated: false,
        user_id: 0,
        user_info: null,
      }
    }

    case LOGOUT_ERROR_USER: {
      return {
        ...state,
        isWaiting: false,
        authenticated: true,
        showLoginPage: true,
      }
    }

    case USER_LOGIN_MODE: {
      return { ...state, showLoginPage: true }
    }

    case USER_SIGNUP_MODE: {
      return { ...state, showLoginPage: false }
    }

    case SESSION_SUCCESS_USER: {
      if (action.userData !== null && action.userData !== '') {
        return {
          ...state,
          isWaiting: false,
          authenticated: true,
          user_info: action.userData,
          user_id: action.userData.user_id,
          existPersonalInformation: action.existPersonalInformation,
        }
      }

      return { ...state, isWaiting: false, user_id: 0 }
    }

    case UPDATE_ACCOUNT_INFO_SUCCESS: {
      if (action.userData !== null && action.userData !== '') {
        return {
          ...state,
          user_info: action.userData,
          existPersonalInformation: action.existPersonalInformation,
        }
      }
      break
    }

    case UPDATE_ACCOUNT_INFO_ERROR: {
      return { ...state, message: action.message }
    }

    case OPEN_MODAL_LOGIN: {
      return { ...state, modalIsOpenLogin: true }
    }

    case CLOSE_MODAL_LOGIN: {
      return { ...state, modalIsOpenLogin: false }
    }

    case PERSONAL_INFORMATION_SUCCESS: {
      if (action.userData !== null && action.userData !== '') {
        return {
          ...state,
          user_info: action.userData,
          existPersonalInformation: action.existPersonalInformation,
        }
      }
      break
    }

    default: {
      return state
    }
  }

  return false
}
