import { CLOSE_MESSAGETE_SNACBAR, OPEN_MESSAGETE_SNACBAR } from '../constants'

export const initialState = {
  open: false,
  message: '',
  variant: 'success',
}

export default function snackbar(state = initialState, action) {
  switch (action.type) {
    case OPEN_MESSAGETE_SNACBAR: {
      return {
        ...state,
        open: true,
        message: action.payload.message,
        variant: action.payload.variant,
      }
    }

    case CLOSE_MESSAGETE_SNACBAR: {
      return { ...state, open: false }
    }

    default: {
      return state
    }
  }
}
