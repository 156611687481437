import React, { useState } from 'react'
import { connect } from 'react-redux'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Drawer,
  AppBar,
  Button,
  Stack,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { logOut } from '@/actions/user'
import { AppDrawerNavList } from '@/components/molecules/AppDrawerNavList/AppDrawerNavList'
import { AppDrawerPersonalMenu } from '@/components/molecules/AppDrawerPersonalMenu/AppDrawerPersonalMenu'

type Props = {
  user: any
  header: any
  authenticated: boolean
  dispatch: any
  children: any
  asPath: string
}

function AppDrawer(props: Props) {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const theme = useTheme()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    props.dispatch(logOut())
    router.push('/')
  }

  const classes = useStyles({})

  let redirectUrl
  let redirectPath

  if (router?.asPath && router?.pathname) {
    redirectUrl = encodeURIComponent(router.asPath)
    redirectPath = encodeURIComponent(router.pathname)
  }

  const signupUrl = redirectUrl
    ? `/signup?redirectUrl=${redirectUrl}&redirectPath=${redirectPath}`
    : '/signup'

  return (
    <div className={classes.root}>
      <div>
        <AppBar color="default" className={props.header?.hideHeader ? classes.hideTab : ''}>
          <Toolbar disableGutters={!open}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Stack direction="row" alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={classNames(open && classes.hide)}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
                <Link href="/">
                  <div className={classes.flex}>
                    <img src={'/images/media/logo.png'} alt="PlaBase" style={{ width: '150px' }} />
                  </div>
                </Link>
              </Stack>
              <Stack direction="row" alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="search"
                  onClick={() => router.push('/search')}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                <Box>
                  {props.authenticated ? (
                    <AppDrawerPersonalMenu handleLogout={handleLogout} />
                  ) : (
                    <Link href={signupUrl}>
                      <Button style={{ color: theme.palette.text.primary }}>無料会員登録</Button>
                    </Link>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer open={open} onClose={handleDrawerClose}>
        <div tabIndex={0} role="button" onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
          <div className={classes.drawerHeader}>
            <span>
              {props.authenticated ? (
                <Typography>
                  {props.user?.familyname ?? ''} {props.user?.firstname ?? ''}
                  さん
                </Typography>
              ) : (
                <Link href="/signup">
                  <Button variant="contained" color="inherit" className={classes.button}>
                    会員登録
                  </Button>
                </Link>
              )}
            </span>
            <span>
              <IconButton onClick={handleDrawerClose} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </span>
          </div>
          <Typography variant="body1" color="inherit" className={classes.caption}>
            日本初！無料で気軽に使える、 プラスチック材料のデータベースです。
          </Typography>
          <Divider />
          <AppDrawerNavList authenticated={props.authenticated} />
        </div>
      </Drawer>
      <main
        className={
          props.header?.hideHeader ? `${classes.content} ${classes.contentTop}` : classes.content
        }
      >
        {props.children}
      </main>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    flex: {
      flex: 1,
      marginLeft: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
      color: 'black',
      backgroundColor: 'rgb(224, 224, 224)',
    },
    caption: {
      width: 230,
      fontSize: '12px',
      margin: theme.spacing(1),
      wordWrap: 'break-word',
    },
    content: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      paddingTop: theme.spacing(1),
      height: 'calc(100% - 56px)',
      marginTop: 56,
      [theme.breakpoints.up('sm')]: {
        height: 'calc(100% - 64px)',
        marginTop: 64,
      },
    },
    contentTop: {
      marginTop: 0,
    },
    list: {
      width: 280,
    },
    listText: {
      textAlign: 'right',
    },
    drawerHeader: {
      width: 280,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    hide: {
      display: 'none',
    },
    hideTab: {
      display: 'none',
    },
  }),
)

const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
  header: state.header,
  user: state.user.user_info,
})

export default connect(mapStateToProps)(AppDrawer)
