import { useCallback } from 'react'
import Cookies from 'js-cookie'
import { createClient } from 'urql'

export const useUrqlClient = () =>
  useCallback(
    () =>
      createClient({
        url: String(process.env.NEXT_PUBLIC_APOLLO_SERVER_URL),
        suspense: false,
        requestPolicy: 'cache-and-network',
        fetchOptions: {
          headers: {
            authorization: `Bearer ${Cookies.get('token')}`,
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Cookies.get('token')],
  )
