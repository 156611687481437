import { CONTACT_DETAILS_FORM_SUCCESS, CONTACT_INITIAL_VALUES } from '../constants'

const initialState = {
  currentContact: [],
  data: {},
}

export default function contact_details(state = initialState, action) {
  switch (action.type) {
    case CONTACT_DETAILS_FORM_SUCCESS: {
      return { ...state, contactDetails: action.contactDetails }
    }

    case CONTACT_INITIAL_VALUES: {
      return { ...state, data: action.data }
    }

    default: {
      return state
    }
  }
}
