import { grey, red } from '@mui/material/colors'
import { createTheme, adaptV4Theme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
  }
}

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }
}

export const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#f9f9f9',
      },
      grey: {
        main: grey[300],
        dark: grey[400],
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  }),
)
