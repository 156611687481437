export const START_SEARCH_LOADING_ANIMATION = 'START_SEARCH_LOADING_ANIMATION'
export const END_SEARCH_LOADING_ANIMATION = 'END_SEARCH_LOADING_ANIMATION'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_ERROR = 'SEARCH_ERROR'

export const SEARCH_ITEM_ESTIMATE_NUMBER = 'SEARCH_ITEM_ESTIMATE_NUMBER'
export const INITIAL_ITEM_COUNT = 'INITIAL_ITEM_COUNT'
export const DETAILS_SUCCESS = 'DETAILS_SUCCESS'
export const DETAILS_ERROR = 'DETAILS_ERROR'
export const CONTACT_DETAILS_FORM_SUCCESS = 'CONTACT_DETAILS_FORM_SUCCESS'
export const CONTACT_INITIAL_VALUES = 'CONTACT_INITIAL_VALUES'
export const VISIT_WAY = 'VISIT_WAY'
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS'
export const GET_FLAG_SUCCESS = 'GET_FLAG_SUCCESS'
export const CHECK_SUCCESS = 'CHECK_SUCCESS'
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS'
export const SAVE_USER_SEARCH_SUCCESS = 'SAVE_USER_SEARCH_SUCCESS'
export const GET_USER_SEARCH_SUCCESS = 'GET_USER_SEARCH_SUCCESS'
export const GET_ONE_SEARCH = 'GET_ONE_SEARCH'
export const CHANGED_SLIDER = 'CHANGED_SLIDER'
export const GET_FULLTEXT_SUCCESS = 'GET_FULLTEXT_SUCCESS'
export const CLOSE_MESSAGETE_SNACBAR = 'CLOSE_MESSAGETE_SNACBAR'
export const OPEN_MESSAGETE_SNACBAR = 'OPEN_MESSAGETE_SNACBAR'
export const CLOSE_FULLSCREEN_DIALOG = 'CLOSE_FULLSCREEN_DIALOG'
export const OPEN_FULLSCREEN_DIALOG = 'OPEN_FULLSCREEN_DIALOG'
// acount redux start
// acount redux end

export const GET_SEARCH_FIELD_SUCCESS = 'GET_SEARCH_FIELD_SUCCESS'
export const SAVE_COLUMN_SUCCESS = 'SAVE_COLUMN_SUCCESS'
export const GET_SAVED_SUCCESS = 'GET_SAVED_SUCCESS'
export const GET_ONE_SAVE = 'GET_ONE_SAVE'
export const GET_ONE_PRESET = 'GET_ONE_PRESET'
export const FILTER_SEARCH_FIELD = 'FILTER_SEARCH_FIELD'

// authentications
export const LOGIN_SUCCESS_USER = 'LOGIN_SUCCESS_USER'
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER'
export const SIGNUP_SUCCESS_USER = 'SIGNUP_SUCCESS_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE'
export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR'
export const ACCOUNT_DELETE_RESET = 'ACCOUNT_DELETE_RESET'
export const PASSWORD_CURRECT = 'PASSWORD_CURRECT'
export const LOGOUT_SUCCESS_USER = 'LOGOUT_SUCCESS_USER'
export const LOGOUT_ERROR_USER = 'LOGOUT_ERROR_USER'
export const UPDATE_ACCOUNT_INFO_SUCCESS = 'UPDATE_ACCOUNT_INFO_SUCCESS'
export const USER_LOGIN_MODE = 'USER_LOGIN_MODE'
export const USER_SIGNUP_MODE = 'USER_SIGNUP_MODE'
export const SESSION_SUCCESS_USER = 'SESSION_SUCCESS_USER'
export const OPEN_MODAL_LOGIN = 'OPEN_MODAL_LOGIN'
export const CLOSE_MODAL_LOGIN = 'CLOSE_MODAL_LOGIN'
export const REVERT_FLAG = 'REVERT_FLAG'
export const INIT_FLAG = 'INIT_FLAG'
export const DEFAULT_FLAG = 'DEFAULT_FLAG'
export const GET_MINMAX_SUCCESS = 'GET_MINMAX_SUCCESS'
export const CHANGED_MINMAX = 'CHANGED_MINMAX'
export const SLIDER_CHECK_SUCCESS = 'SLIDER_CHECK_SUCCESS'
export const RESET_SAVED_SEARCH = 'RESET_SAVED_SEARCH'
export const OPEN_MODAL_PASSWORD = 'OPEN_MODAL_PASSWORD'
export const UPDATE_ACCOUNT_INFO_ERROR = 'UPDATE_ACCOUNT_INFO_ERROR'
export const FORGET_PASSWORD_RESET = 'FORGET_PASSWORD_RESET'
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET'

// Bookmark
export const ADD_BOOKMARK = 'ADD_BOOKMARK'

// Wordpress bookmark
export const ADD_WORDPRESS_FAVORITE = 'ADD_WORDPRESS_FAVORITE'

// resin
export const GET_RESIN_LIST = 'GET_RESIN_LIST'

// lang
export const SWITCH_LANGUEAGE = 'SWITCH_LANGUEAGE'

// recommend
export const RECOMMEND_SUCCESS = 'RECOMMEND_SUCCESS'
export const RESET_SEARCH_ITEMS = 'RESET_SEARCH_ITEMS'

export const SET_SELECT_ROW_KEYS = 'SET_SELECT_ROW_KEYS'
export const RESET_SELECTED_ROW_KEYS = 'RESET_SELECTED_ROW_KEYS'

export const SIMILAR_SEARCH_CHANGED_SLIDER = 'SIMILAR_SEARCH_CHANGED_SLIDER'
export const SIMILAR_SEARCH_CHANGED_SLIDER_CHECKED = 'SIMILAR_SEARCH_CHANGED_SLIDER_CHECKED'
export const BACKUP_SEARCH_QUERY = 'BACKUP_SEARCH_QUERY'
export const BACKUP_ITEM_PROPERTY = 'BACKUP_ITEM_PROPERTY'

export const PERSONAL_INFORMATION_SUCCESS = 'PERSONAL_INFORMATION_SUCCESS'

export const SET_META_TAGS = 'SET_META_TAGS'
export const TOGGLE_HEADER = 'TOGGLE_HEADER'
