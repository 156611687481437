import { combineReducers } from 'redux'
import bookmark from './bookmark'
import contact_details from './contact_details'
import customize_columns from './customize_columns'
import details from './details'
import dialog from './dialog'
import header from './header'
import language from './language'
import recommend from './recommend'
import search from './search'
import search_field from './search_field'
import snackbar from './snackbar'
import user from './user'
import wordpress from './wordpress'

const plabaseApp = combineReducers({
  details,
  dialog,
  search,
  contact_details,
  bookmark,
  customize_columns,
  user,
  search_field,
  recommend,
  wordpress,
  header,
  snackbar,
  language,
})
export default plabaseApp
