export function fetchUser(user) {
  /* eslint-disable no-underscore-dangle */
  if (!user) return false
  const userData = {
    _id: user._id,
    user_id: user.user_id,
    email: user.email,
    name: user.firstname ? `${user.familyname}${user.firstname}` : undefined,
    familyname: user.familyname,
    firstname: user.firstname,
    company: user.company,
    department: user.department,
    business: user.business,
    postcode: user.postcode,
    address: user.address,
    phone: user.phone,
    language: user.language,
    created_time: user.created_time,
    includeNull: user.includeNull,
  }

  return userData
  /* eslint-enable no-underscore-dangle */
}
