import { CLOSE_MESSAGETE_SNACBAR, OPEN_MESSAGETE_SNACBAR } from '../constants'

export const handleSuccessMessage = (message, variant) => ({
  payload: { message, variant },
  type: OPEN_MESSAGETE_SNACBAR,
})

export const handleMessageOpen =
  (message, variant, delay = 5000) =>
  (dispatch) => {
    dispatch(handleSuccessMessage(message, variant))
    setTimeout(() => {
      dispatch(handleMessageClose())
    }, delay)
  }

export const handleMessageClose = () => ({
  type: CLOSE_MESSAGETE_SNACBAR,
})
