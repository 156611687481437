const content = [
  {
    lang: 'jp',
    page: {
      home: {
        search_plastic: '成形材料を探す',
        search_plastic_sub: 'Search from Plastic Data Base',
        estimate_search_result: '該当件数',
        number: '件',
        search: '検索する',
        show_all: '検索条件を追加する',
        show_all_close: '検索条件を閉じる',
        search_value: '物性値で検索',
        search_value_sub: 'Search in property values',
        search_box_text: '各物性値の範囲を指定することによって、検索結果を絞り込むことができます。',
      },
      core: {
        search_result: '検索結果',
        estimate_search_result: '予測検索数',
        number: '件',
        compare: '比較',
        similarity_search_button: '類似検索',
        grade: 'グレード',
        saved_search_query: '検索条件の保存',
        search: '検索する',
        reset: 'リセット',
        latest_search_query: '直近の検索項目',
        times_ago: '回前の検索項目',
        saved_search_description: '保存した検索条件・検索履歴から検索',
        choose_saved_search_qeury: '過去の検索条件',
        compare_button_alarm: 'グレードを2つから5つの間で選択してください',
        similarity_search_alarm: 'グレードを一つお選びください。',
        close: '閉じる',
        object_id: 'Object ID',
      },
      detail: {
        back_to_search: '検索ページに戻る',
        product_description: '製品情報',
        maker: 'メーカー',
        department_name: '部署名',
        resin_name: '樹脂名',
        product_name: '商品名',
        grade: 'グレード',
        iso_name: 'ISO材質表示',
        segments: '使用区分',
        pdf_download: 'PDFダウンロード',
        share_by_mail: 'メール共有',
        add_bookmart: 'ブックマーク',
        remove_bookmark: 'ブックマークを削除',
        properties: '物性値一覧',
        property_name: '名称',
        property_maker_name: 'メーカー名称',
        test_method: '検査方法',
        value: '値',
        unit: '単位',
        usage_th: '主な用途',
        color_th: 'カラー',
        characteristic_th: '主な特徴',
        notes: '備考',
        total_catalog: '総合カタログ',
        catalog: '製品カタログ',
        update_date: '最終更新日',
        year: '年',
        month: '月',
        day: '日',
      },
      compare: {
        contact_us: 'お問い合わせ',
        back_to_search: '検索ページに戻る',
        pdf_download: 'PDFダウンロード',
        share_by_mail: 'メール共有',
        test_method: '検査方法',
        compare_grade_1: '比較グレード 1',
        compare_grade_2: '比較グレード 2',
        compare_grade_3: '比較グレード 3',
        compare_grade_4: '比較グレード 4',
        compare_grade_5: '比較グレード 5',
        csv_download: 'エクセルダウンロード',
      },
      share_by_mail: {
        title: 'メール共有',
        description: 'グレード - ',
        to: '送信先メールアドレス:(複数入力の場合はカンマ区切りで入力)',
        enpty_email: '送信先を入力してください。',
        email_placeholder: '送信先メールアドレスを入力してください',
        Send_copy_to_me: '同様の内容を受け取る',
        note: '備考:',
        submit: '送信',
      },
      table_customize: {
        select_default: '保存した条件を選択',
        button: '列のカスタマイズ',
        chosen_column: '選択された列',
        number: '件',
        save_table_customize: '列のカスタマイズを保存する',
        cancel: 'キャンセル',
        submit: '実行',
        reset: 'リセット',
        reset_to_default: '初期状態に戻す',
      },
      search_box: {
        choose_maker: 'メーカーを選択',
        choose_resin: '樹脂名を選択',
        choose_iso: 'ISO材質表示を選択',
        choose_product: '商品名を選択',
        choose_flame_rating: '燃焼性を選択',
        fulltext_placeholder: '検索ワードを入力...',
        text_search: 'キーワード入力',
        text_search_example: '(例) 耐熱',
        grade_search_placeholder: 'グレード名を入力...',
        select_all: 'すべてを選択',
        close_text: '閉じる',
        no_data_table_result: '検索条件に一致する情報は見つかりませんでした。',
      },
      header: {
        logo: 'PlaBase',
        logo_sub: 'プラスチック成形材料データベース',
        nav_about: 'PlaBaseについて',
        nav_help: 'ヘルプ',
        nav_maker: 'メーカーの方',
        dropdown_account: 'アカウント設定',
        dropdown_browsing_history: '樹脂閲覧履歴',
        dropdown_bookmark: '樹脂ブックマーク',
        dropdown_favorite: '記事ブックマーク',
        dropdown_contact_history: 'お問い合わせ履歴',
        dropdown_logout: 'ログアウト',
        contact_us: 'お問い合わせフォーム',
        login: 'ログイン',
        sign_up: '新規登録',
        guest_user: 'ゲストユーザー',
        choose_lang: 'LANGUAGE',
      },
      footer: {
        about: 'PlaBaseについて',
        terms: '利用規約',
        privacy_policy: 'プライバシーポリシー',
        trademark: '商標について',
        contact_us: 'お問い合わせフォーム',
        faq: 'よくあるご質問(FAQ)',
        copyright: '©2017 PlaBase',
      },
      utility: {
        browsing_history_title: '閲覧履歴',
        bookmark_title: '樹脂ブックマーク',
        favorite_title: '記事ブックマーク',
        contact_history_title: 'お問い合わせ履歴',
        browsing_history_empty_content: '樹脂閲覧履歴がありません。',
        bookmark_empty_content: '樹脂ブックマークがありません。',
        favorite_empty_content: '記事ブックマークがありません。',
        contact_history_empty_content: '問い合わせ履歴がありません。',
        grade: 'グレード',
        maker: 'メーカー',
        resin_name: '樹脂名',
        product_name: '商品名',
        browsing_date: '閲覧日時',
        delete: '削除',
        contact_history_id: '問い合わせID',
        contact_history_date: '問い合わせ日時',
        contact_history_details: '問い合わせ内容',
      },
      account: {
        confirm_form: '入力内容の確認',
        back_to_home: 'ホームに戻る',
        email: 'メールアドレス',
        email_placeholder: '例：sample@plabase.com',
        password: 'パスワード',
        password_placeholder: '8文字以上、半角英数字、記号のみ',
        familyname: '姓',
        familyname_placeholder: '例：田中',
        firstname: '名',
        firstname_placeholder: '例：太郎',
        company: '会社名',
        company_placeholder: '例：PlaBase株式会社',
        department: '部署名',
        department_placeholder: '例：製造部',
        business: '業種',
        business_default_option: '業種名を選択してください',
        industry: '業界',
        industry_default_option: '業界名を選択してください',
        field: '分野',
        field_default_option: '分野を選択してください',
        jobTitle: '役職名',
        jobTitle_default_option: '役職名を選択してください',
        postcode: '郵便番号',
        postcode_placeholder: '半角数字、ハイフンなし',
        address: '住所',
        address_placeholder: '例：東京都新宿区西新宿2丁目8-1',
        phone: '電話番号',
        phone_placeholder: '半角数字、ハイフンなし',
        agreement: 'PlaBaseの利用規約及びプライバシーポリシーに同意します。',
        term_link: ' → 利用規約を確認する',
        privacy_link: ' → プライバシーポリシーを確認する',
        category: 'カテゴリー',
        option: 'オプション',
        login: 'ログイン',
        login_here: 'ログインはこちら',
        sign_up: '新規登録',
        sign_up_here: '新規登録はこちら',
        sign_up_confirm_button: '次へ',
        fix_signup_form: '修正',
        sign_up_button: 'アカウント新規登録',
        cancel: 'キャンセル',
        submit_edit: '編集の保存',
        forget_password: 'パスワードを忘れた方',
        submit_forget_password: 'パスワードリセットメールを送信',
        settings: 'アカウント設定',
        edit: 'アカウント編集',
        search_setting: '検索条件の設定',
        delete: 'アカウント削除',
        delete_account: 'アカウントを削除する',
        change_password: 'パスワードを変更(こちらをクリック)',
        require_login: 'ログインをしてください',
        delete_title: 'アカウントの削除',
        delete_confirm_password: '送信',
        delete_confirm_text: '削除は取り消せませんがよろしいでしょうか。',
        delete_cancel: 'キャンセル',
        delete_submit: '削除',
        delete_success: 'アカウントを削除しました',
        invalid_url: '有効なリンクではありません',
      },
      validation_message: {
        required: '必須',
        change_title: 'パスワードを変更',
        reset_title: 'パスワードをリセット',
        current_password: '現在のパスワード',
        new_password: '新しいパスワード',
        confirm_new_password: '新しいパスワードの確認',
        cancel: 'キャンセル',
        save_changes: '変更を保存',
        password_placeholder: 'パスワードを入力してください',
        current_password_null: 'パスワードを入力してください',
        new_password_null: '新しいパスワードを入力してください',
        password_count_error: 'パスワードには8文字以上である必要があります',
        password_number_error: 'パスワードには数字が少なくとも1つ含まれている必要があります',
        password_lowercase_error: 'パスワードには小文字が少なくとも1つ含まれている必要があります',
        password_uppercase_error: 'パスワードには大文字が少なくとも1つ含まれている必要があります',
        confirm_password_null: '確認用パスワードを入力してください',
        password_not_match: 'パスワードと確認用パスワードが一致しません',
        incurrect_password: '正しいパスワードを入力してください',
        invalid_phone: '電話番号が正しくありません',
        invalid_email: 'メールアドレスが正しくありません',
        send_password: 'ご登録のメールアドレスへパスワード変更のための確認メールを送信しました。',
        user_exist: 'ご入力いただいたメールアドレスが既に他のアカウントで登録されております。',
        user_not_exist: '入力されたメールアドレスは登録されていません。',
        count_error: '文字数が上限を越えております。',
      },
      contact_us: {
        button: 'お問い合わせ',
        title: 'お問い合わせ',
        content: 'お問い合わせ内容',
        maker: 'メーカー',
        maker_placeholder: 'メーカー名を入力してください',
        resin_name: '樹脂名',
        resin_name_placeholder: '樹脂名を入力してください',
        product_name: '商品名',
        product_name_placeholder: '商品名を入力してください',
        grade: 'グレード',
        grade_placeholder: 'グレード名を入力してください',
        name: 'お名前',
        name_placehoder: 'お名前を入力してください',
        category: 'カテゴリー',
        category_default_option: '質問項目を選択してください',
        categories: [
          '樹脂材料について',
          '価格について',
          '見積もり依頼',
          'サンプル依頼',
          '試作について',
          'その他のお問い合わせ',
        ],
        submit: '送信',
        content_placeholder: 'お問い合わせ内容をお書きください。',
      },
      message: {
        sending_email: 'メール送信中です。',
        sending_not_allowed: '許可されていない送信',
        sent_success_email: 'メール共有しました。',
        sent_error_email:
          '送信エラーになりました。同じ商品をメール共有する場合、一定時間を空けた上でご利用ください。',
        submiting_contact: 'お問い合わせ送信中です。',
        submitted_success_contact: 'お問い合わせを送信しました。',
        submitted_error_contact:
          '送信エラーになりました。大変申し訳ございませんが、再度お問い合わせください。',
        success_login: 'ログインしました。',
        error_login: 'ログインできませんでした。',
        success_signup: '登録完了しました。',
        error_signup: '登録できませんでした。',
        success_account_edit: '設定を保存しました。',
        error_account_edit: '設定を保存できませんでした。',
        logout: 'ログアウトしました。',
        saved_search_query: '検索条件を保存しました。',
        saved_search_query_remove: 'この検索条件を削除しますか？',
        saved_table_customize: '列のカスタマイズを保存しました。',
        saved_table_customize_remove: 'この列プリセットを削除しますか？',
        prompt_enter_save_same: '保存名を入力してください',
        reset_password_success: 'パスワードが変更されました',
        user_exist: 'ご入力いただいたメールアドレスが既に他のアカウントで登録されております。',
        not_match_name_password: 'ご入力されたメールアドレスまたはパスワードは正しくありません。',
        password_count_error: 'パスワードには8文字以上である必要があります',
        wrong_password: '正しいパスワードを入力してください',
        not_allowed: '許可されていません',
        requiredAgreement: '「利用規約及びプライバシーポリシー」へ同意してください。',
        too_many_requests:
          '入力回数の上限を超えたため、ご利用いただけません。数時間後にもう一度お試しください',
        validation_failed: '入力内容に誤りがあります',
        validation_error: '入力検証中にエラーが発生しました',
      },
      categories: {
        basic: '基礎的性質',
        heat: '熱的性質',
        machine: '機械的性質',
        electrical: '電気的性質',
        optically: '光学的性質',
        film: 'フィルム特性',
        biodegradation: '生分解性',
        building: '成形性',
        standard_building: '標準成形条件',
        filler: 'フィラー含有率',
        sliding_motions: '摺動性',
        information: '基本的項目',
      },
      labels: {
        A0: '密度',
        A1: '吸水率',
        A2: '接着性',
        A3: '耐酸性',
        A4: '耐アルカリ',
        A5: '耐溶剤性',
        B0: '比熱',
        B1: 'ビカット軟化温度',
        B2: '加熱後外観',
        B3: '荷重たわみ温度',
        B4: '柔軟温度',
        B5: '脆化温度',
        B6: '線膨張係数(流動)',
        B7: '線膨張係数(直角)',
        B8: '線膨張係数',
        B9: '熱伝導率',
        B10: '加熱変形性',
        B11: '融点',
        B12: '燃焼性',
        B13: '溶融粘度',
        B14: 'ガラス転移点',
        B15: '温度インデックス',
        C0: '圧縮強度',
        C1: '引張降伏応力',
        C2: '引張破壊応力',
        C3: '引張破壊歪み',
        C4: '引張弾性率',
        C5: '100％モジュラス',
        C6: '曲げ強度',
        C7: '曲げ弾性率',
        C8: 'オルゼン剛性',
        C9: '反発弾性',
        C10: '引裂強さ',
        C11: '圧縮永久歪',
        C12: 'シャルピー衝撃強度',
        C13: 'アイゾット衝撃強度',
        C14: '表面硬度',
        C15: 'ポアソン比',
        C16: 'ヤング率',
        C17: '引張強さ',
        C18: '引張伸び',
        C19: '曲げひずみ',
        C20: '降伏ひずみ',
        C21: '50%ひずみ応力',
        D0: '体積抵抗率',
        D1: '表面抵抗率',
        D2: '誘電率',
        D3: '誘電正接',
        D4: '絶縁耐力',
        D5: '耐アーク性',
        D6: '絶縁抵抗',
        D7: '耐トラッキング性',
        D8: '比誘電率',
        E0: 'ヘイズ',
        E1: '全光線透過率',
        E2: '屈折率',
        F0: '厚み',
        F1: '霞度',
        F2: '光沢',
        F3: 'エルメンドルフ　引裂強さ',
        F4: 'ヒートシール性',
        F5: 'ヒートシール強度',
        F6: 'ガス透過度',
        G0: 'プラトー到達期間',
        G1: 'プラトー到達分解度',
        H0: '成形収縮率MD',
        H1: '成形収縮率TD',
        H2: '成形収縮率',
        H3: 'ＭＦＲ',
        H4: 'ＭＶＲ',
        H5: 'スパイラルフロー',
        I0: '樹脂温度',
        I1: '金型温度',
        I2: '成形圧力',
        I3: 'シリンダ先端部　設定温度',
        I4: 'ダイ部　設定温度',
        I5: 'ダイ出口部　設定温度',
        I6: '乾燥条件',
        J0: 'フィラー含有率',
        K0: '比摩耗量 (対炭素鋼／評価材側／面圧0.49MPa, 30cm/s)',
        K1: '比摩耗量 (対炭素鋼／相手材側／面圧0.49MPa, 30cm/s)',
        K2: '動摩擦係数 (対炭素鋼／面圧0.49MPa, 30cm/s)',
        K3: '比摩耗量 (対炭素鋼／評価材側／面圧0.98MPa, 30cm/s)',
        K4: '比摩耗量 (対炭素鋼／相手材側／面圧0.98MPa, 30cm/s)',
        K5: '動摩擦係数 (対炭素鋼／面圧0.98MPa, 30cm/s)',
        K6: '比摩耗量 (対M90-44／評価材側／面圧0.06MPa, 15cm/s)',
        K7: '比摩耗量 (対M90-44／M90-44側／面圧0.06MPa, 15cm/s)',
        K8: '動摩擦係数 (対M90-44／面圧0.06MPa, 15cm/s)',
        maker: 'メーカー',
        departmant: '部署名',
        resin_name: '樹脂名',
        iso_name: 'ISO材質表示',
        product_name: '商品名',
        grade: 'グレード',
        segment: '使用区分',
        color: 'カラー',
        characteristic: '特徴',
        usage: '用途',
        sales_sentence: 'セールスポイント',
        tags: 'タグ',
        active_item: '製造(ディスコン)',
        category: 'カテゴリー',
        notes: '備考',
      },
      wordpress_favorite: {
        add_favorite: 'ブックマーク',
        remove_favorite: 'ブックマーク削除',
      },
    },
  },
  {
    lang: 'en',
    page: {
      home: {
        search_plastic: 'Search molding materials',
        search_plastic_sub: 'Search from Plastic Data Base',
        estimate_search_result: 'Estimate items:',
        number: '',
        search: 'Search',
        show_all: 'Filter',
        show_all_close: 'Close',
        search_value: 'Propery search',
        search_value_sub: 'Search in property values',
        search_box_text:
          'By specifying the range of each property value, you can narrow the search results.',
      },
      core: {
        search_result: 'Seach result:',
        estimate_search_result: 'estimate items',
        number: '',
        compare: 'Compare',
        similarity_search_button: 'SIMILARITY SEARCH',
        saved_search_query: 'Save search query',
        search: 'Search',
        reset: 'Reset',
        latest_search_query: 'Latest search query',
        times_ago: 'Previous search item',
        saved_search_description: 'Search from saved search query/search history',
        choose_saved_search_qeury: 'Choose saved search',
        full_text_serach: 'Full text serach',
        maker: 'Maker',
        resin_name: 'Resin name',
        iso_name: 'ISO material name',
        product_name: 'Product name',
        flame_rating: 'Flame rating',
        compare_button_alarm: 'Please choose a grade range of 2 to 5.',
        similarity_search_alarm: 'Please choose single grade.',
        close: 'Close',
        object_id: 'Object ID',
      },
      detail: {
        back_to_search: 'Back to search',
        product_description: 'Product description',
        maker: 'Maker',
        department_name: 'Department',
        resin_name: 'Resin name',
        product_name: 'Product name',
        grade: 'Grade',
        iso_name: 'ISO Material Display',
        segments: 'Segments',
        pdf_download: 'PDF download',
        share_by_mail: 'Share By Email',
        add_bookmart: 'Add Bookmark',
        remove_bookmark: 'Remove Bookmark',
        properties: 'Properties',
        property_name: 'Property name',
        property_maker_name: 'Property maker name',
        test_method: 'Test method',
        value: 'Value',
        unit: 'Unit',
        usage_th: 'Usage',
        color_th: 'Color',
        characteristic_th: 'Characteristic',
        notes: 'notes',
        total_catalog: 'General leaflet',
        catalog: 'Leaflet',
        update_date: 'Last updated',
        year: '/',
        month: '/',
        day: '/',
      },
      compare: {
        contact_us: 'Contact Us',
        back_to_search: 'Back to search',
        pdf_download: 'PDF download',
        share_by_mail: 'Share By Email',
        test_method: 'Test method',
        compare_grade_1: 'Compare grade 1',
        compare_grade_2: 'Compare grade 2',
        compare_grade_3: 'Compare grade 3',
        compare_grade_4: 'Compare grade 4',
        compare_grade_5: 'Compare grade 5',
        csv_download: 'Excel Download',
      },
      share_by_mail: {
        title: 'Share By Email',
        description: 'Grade - ',
        to: 'To:(Separate multiple email addresses with commas)',
        enpty_email: 'Please enter a sender email address.',
        email_placeholder: 'Please enter a sender email address.',
        Send_copy_to_me: 'Send a copy to me',
        note: 'Note:',
        submit: 'Submit',
      },
      table_customize: {
        select_default: 'Select saved query',
        button: 'Customize columns',
        chosen_column: 'Selected columns',
        number: 'items',
        save_table_customize: 'Save customized column',
        cancel: 'Cancel',
        submit: 'Execute',
        reset: 'Reset',
        reset_to_default: 'Back to default',
      },
      search_box: {
        choose_maker: 'Choose maker',
        choose_resin: 'Choose resin',
        choose_iso: 'Choose ISO material name.',
        choose_product: 'Choose product name',
        choose_flame_rating: 'Choose flame rating',
        fulltext_placeholder: 'Enter keywords',
        text_search: 'Type keyword',
        text_search_example: '(Example) Gear',
        select_all: 'Select All',
        grade_search_placeholder: 'Enter grade',
        close_text: 'CLOSE',
        no_data_table_result: 'There is no data to display',
      },
      header: {
        logo: 'PlaBase',
        logo_sub: 'Plastic molding material database',
        nav_about: 'About us',
        nav_help: 'Help',
        nav_maker: 'For makers',
        dropdown_account: 'Account setting',
        dropdown_browsing_history: 'Browsing history',
        dropdown_bookmark: 'Bookmark',
        dropdown_favorite: 'Favorite',
        dropdown_contact_history: 'Contact history',
        dropdown_logout: 'Logout',
        contact_us: 'Inquiry form',
        login: 'Login',
        sign_up: 'Sign up',
        guest_user: 'Guest user',
        choose_lang: 'Choose language',
      },
      footer: {
        about: 'About us',
        terms: 'Terms',
        privacy_policy: 'Privacy policy',
        trademark: 'Trademark',
        contact_us: 'Contact Us',
        faq: 'FAQ',
        copyright: '©2017 PlaBase',
      },
      utility: {
        browsing_history_title: 'Browsing history',
        bookmark_title: 'Bookmark',
        favorite_title: 'Favorite',
        contact_history_title: 'Contact history',
        browsing_history_empty_content: 'Browsing Histories not Available...',
        bookmark_empty_content: 'Bookmark Histories not Available...',
        favorite_empty_content: 'Favorite List not Available...',
        contact_history_empty_content: 'Contact Histories not Available...',
        grade: 'Grade',
        maker: 'Maker',
        resin_name: 'Resin name',
        product_name: 'Product name',
        browsing_date: 'Browsing date',
        delete: 'Delete',
        contact_history_id: 'Contact history id',
        contact_history_date: 'Contact history date',
        contact_history_details: 'Contact history details',
      },
      account: {
        confirm_form: 'Confirm Form',
        back_to_home: 'Back to home',
        email: 'Email',
        email_placeholder: 'Please enter your email address.',
        password: 'Password',
        password_placeholder: 'Please enter your password.',
        familyname: 'Family name',
        familyname_placeholder: 'Please enter your family name.',
        firstname: 'Firstname',
        firstname_placeholder: 'Please enter your firstname.',
        company: 'Company',
        company_placeholder: 'Please enter your company name.',
        business: 'Business',
        business_default_option: 'Please enter your business name.',
        department: 'Department',
        department_placeholder: 'Please enter your department name.',
        industry: 'Industry',
        industry_default_option: 'Please select an industry name.',
        field: 'Field',
        field_default_option: 'Please select a field name.',
        jobTitle: 'Job title',
        jobTitle_default_option: 'Please select your job title.',
        postcode: 'Postcode',
        postcode_placeholder: 'Please select your postcode.',
        address: 'Address',
        address_placeholder: 'Please enter your address.',
        phone: 'Phone',
        phone_placeholder: 'Please enter your phone number.',
        agreement: 'I agreed to the Terms and Privacy Policy.',
        term_link: ' → Confirm the Terms',
        privacy_link: ' → Confirm the Privacy Policy',
        category: 'Category',
        option: 'Option',
        login: 'Login',
        login_here: 'Login here',
        sign_up: 'Sign up',
        sign_up_here: 'Sign up here',
        sign_up_confirm_button: 'Next',
        fix_signup_form: 'Fix',
        sign_up_button: 'Sign up',
        cancel: 'Cancel',
        submit_edit: 'Save edits',
        forget_password: 'Reset password',
        submit_forget_password: 'Send an email for resetting your password.',
        settings: 'Account settings',
        edit: 'Account edit',
        search_setting: 'Search setting',
        delete: 'Account delete',
        delete_account: 'Delete account',
        change_password: 'Change password(Click here)',
        require_login: 'Please login.',
        delete_title: 'DELETE ACCOUNT',
        delete_confirm_password: 'Submit',
        delete_confirm_text: 'Are you sure you want to delete?',
        delete_cancel: 'Cancel',
        delete_submit: 'Delete',
        delete_success: 'Delete account',
        invalid_url: 'Invalid_url',
      },
      validation_message: {
        required: 'Required',
        change_title: 'CHANGE PASSWORD',
        reset_title: 'Reset password',
        current_password: 'Current password',
        new_password: 'New password',
        confirm_new_password: 'Confirm new password',
        cancel: 'Cancel',
        save_changes: 'Save changes',
        password_placeholder: 'Please enter a password.',
        current_password_null: 'Enter current password..!',
        new_password_null: 'Enter new password..!',
        password_count_error: 'Password must contain at least 8 characters..!',
        password_number_error: 'Password must contain at least one number..!',
        password_lowercase_error: 'Password must contain at least one lowercase letter..!',
        password_uppercase_error: 'Password must contain at least one uppercase letter..!',
        confirm_password_null: 'Please enter a password for confirmation',
        password_not_match: 'Please enter a password for confirmation',
        incurrect_password: 'Please enter a correct password',
        invalid_phone: 'Phone Number not valid..!',
        invalid_email: 'Email address is not correct format',
        send_password: 'We have send password reset link in your email, please check it..!',
        user_exist: 'E-mail address you entered has already been registered in the other account.',
        user_not_exist: 'This email address does not exist',
        count_error: 'This text is too long!',
      },
      contact_us: {
        button: 'Contact Us',
        title: 'Contact Us',
        content: 'Content of inquiry',
        maker: 'Maker',
        maker_placeholder: 'Please enter a maker name.',
        resin_name: 'Resin name',
        resin_name_placeholder: 'Please enter a resin name.',
        product_name: 'Product name',
        product_name_placeholder: 'Please enter a product name.',
        grade: 'Grade',
        grade_placeholder: 'Please enter a grade.',
        name: 'Name',
        name_placehoder: 'Please enter your name.',
        category: 'Category',
        category_default_option: 'Please select a category',
        categories: [
          'About details of the resin',
          'About price',
          'Request for a quotation',
          'Order a sample',
          'Others',
        ],
        submit: 'Submit',
        content_placeholder: 'Please write down the contents of your inquiry',
      },
      message: {
        sending_email: 'sending email',
        sending_not_allowed: 'sending not allowed',
        sent_success_email: 'sending complete email',
        sent_error_email:
          'When sharing the same product by e-mail, please try after a certain period of time.',
        submiting_contact: 'submitting contact message',
        submitted_success_contact: 'Message submitted',
        submitted_error_contact: 'Sorry, please contact us again.',
        success_login: 'Logged in',
        error_login: 'Login failed',
        success_signup: 'Successfully singed up',
        error_signup: 'Signup failed',
        success_account_edit: 'Your settings have been saved.',
        error_account_edit: 'Failed to save settings.',
        logout: 'Logged out',
        saved_search_query: 'saved search query',
        saved_search_query_remove: 'Do You Want to Delete This Saved Search Query?',
        saved_table_customize: 'Saved column customization',
        saved_table_customize_remove: 'Do You Want to Delete This Column Preset?',
        prompt_enter_save_same: 'Please enter saved name',
        reset_password_success: 'Password successfully changed.',
        user_exist: 'E-mail address you entered has already been registered in the other account.',
        not_match_name_password: 'Password is not correct',
        password_count_error: 'Password must contain at least 8 characters..!',
        wrong_password: 'wrong password',
        not_allowed: 'not allowed',
        requiredAgreement: 'Please agree to the Terms and Privacy Policy',
        too_many_requests: 'Too many requests from this IP, please try again after an hour',
        validation_failed: 'Validation Failed',
        validation_error: 'Invalid Item',
      },
      categories: {
        basic: 'Basic property',
        heat: 'Thermal property',
        machine: 'Mechanical property',
        electrical: 'Electrical property',
        optically: 'Optial property',
        film: 'Film property',
        biodegradation: 'Biodegradation',
        building: 'Moldability',
        standard_building: 'Standard molding conditions',
        filler: 'Filer content rate',
        sliding_motions: 'Sliding motions',
        information: 'Basic item',
      },
      labels: {
        A0: 'Density',
        A1: 'Water absorption',
        A2: 'Adhesiveness',
        A3: 'Acid resistance',
        A4: 'Alkali resistance',
        A5: 'Solvent resistance',
        B0: 'Specific heat',
        B1: 'Vicat softening temperature',
        B2: 'After heating appearance',
        B3: 'Deflection temperature under load',
        B4: 'Flexible temperature',
        B5: 'Brittle temperature',
        B6: 'Linear expansion coefficient(liquidity)',
        B7: 'Linear expansion coefficient (right angles)',
        B8: 'Linear expansion coefficient',
        B9: 'Thermal conductivity',
        B10: 'Heating deformability',
        B11: 'Melting point',
        B12: 'Combustion quality',
        B13: 'Melt viscosity',
        B14: 'Glass transition',
        B15: 'Temperature index',
        C0: 'Compressive strength',
        C1: 'Tensile yield stress',
        C2: 'Tensile fracture stress',
        C3: 'Tensile fracture strain',
        C4: 'Tensile modulus of elasticity',
        C5: '100% modulus',
        C6: 'Flexural strength',
        C7: 'Flexural modulus',
        C8: 'Olsen stiffness',
        C9: 'Rebound resilience',
        C10: 'Tear strength',
        C11: 'Compression set',
        C12: 'Charpy impact strength',
        C13: 'Izod impact strength',
        C14: 'Surface hardness',
        C15: 'Poisson coefficient',
        C16: "Young's modulus",
        C17: 'Tensile strength',
        C18: 'Tensile elongation',
        C19: 'Bending strain',
        C20: 'Yield strain',
        C21: '50% strain stress',
        D0: 'Volume resistivity',
        D1: 'Surface resistivity',
        D2: 'Permittivity',
        D3: 'Dielectric loss tangent',
        D4: 'Dielectric strength',
        D5: 'Arc resistance',
        D6: 'Insulation resistance',
        D7: 'Tracking resistance',
        D8: 'Dielectric constant',
        E0: 'Haze degree',
        E1: 'Total light transmittance',
        E2: 'Refractive index',
        F0: 'Thickness',
        F1: 'Haze degree',
        F2: 'Gloss',
        F3: 'Elmendorff　Tear strength',
        F4: 'Heat sealability',
        F5: 'Heat-seal strength',
        F6: 'Gas permeability',
        G0: 'Plateau reaching period',
        G1: 'Plateau reaching resolution',
        H0: 'Molding shrinkage MD',
        H1: 'Molding shrinkage TD',
        H2: 'Molding shrinkage',
        H3: 'MFR',
        H4: 'MVR',
        H5: 'Spiral flow',
        I0: 'Resin temperature',
        I1: 'Tool temperature',
        I2: 'Molding pressure',
        I3: 'Cylinder distal portion　Preset temperature',
        I4: 'Die section preset temperature',
        I5: 'Die outlet preset temperature',
        I6: 'Drying conditions',
        J0: 'Filler content',
        K0: 'Specific wear amount (against carbon steel/evaluation material side/surface pressure 0.49 MPa, 30 cm / s)',
        K1: 'Specific wear amount (against carbon steel / mating material side / surface pressure 0.49 MPa, 30 cm / s)',
        K2: 'Dynamic friction coefficient (against carbon steel / surface pressure 0.49 MPa, 30 cm / s)',
        K3: 'Specific wear amount (Carbon steel / Evaluation material side / Surface pressure 0.98 MPa, 30 cm / s)',
        K4: 'Specific wear amount (against carbon steel / mating material side / surface pressure 0.98 MPa, 30 cm / s)',
        K5: 'Dynamic friction coefficient (Carbon steel / Surface pressure 0.98 MPa, 30 cm / s)',
        K6: 'Specific wear amount (to M90 - 44 / evaluation material side / surface pressure 0.06 MPa, 15 cm / s)',
        K7: 'Specific wear amount (to M 90 - 44 / M 90 - 44 side / surface pressure 0.06 MPa, 15 cm / s)',
        K8: 'Dynamic friction coefficient (to M90 - 44 / face pressure 0.06 MPa, 15 cm / s)',
        maker: 'maker',
        departmant: 'Department',
        resin_name: 'Resin',
        iso_name: 'ISO material',
        product_name: 'Product',
        grade: 'Grade',
        segment: 'Segments',
        color: 'Color',
        characteristic: 'Characteristic',
        usage: 'Use',
        sales_sentence: 'Sales point',
        tags: 'Tags',
        active_item: 'Active',
        category: 'Category',
        notes: 'Notes',
      },
      wordpress_favorite: {
        add_favorite: 'Add Favorite',
        remove_favorite: 'Remove Favorite',
      },
    },
  },
]

module.exports = content
