import React, { useState } from 'react'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Link } from '@/components/atoms/Link'

type Props = {
  handleLogout(): void
}

export function AppDrawerPersonalMenu(props: Props) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-owns={anchorEl ? 'long-menu' : ''}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        size="large"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem>
          <Link href="/account">
            <Typography
              variant="body1"
              component="a"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              アカウント設定
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={'/news_favorite/[page]'} as={'/news_favorite/1'}>
            <Typography
              variant="body1"
              component="a"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              記事ブックマーク
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={'/contact_history/[page]'} as={'/contact_history/1'}>
            <Typography
              variant="body1"
              component="a"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              お問い合わせ履歴
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={'/estimate_history/[page]'} as={'/estimate_history/1'}>
            <Typography
              variant="body1"
              component="a"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              見積り依頼履歴
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={'/sample_history/[page]'} as={'/sample_history/1'}>
            <Typography
              variant="body1"
              component="a"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              サンプル依頼履歴
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={'/bookmark/[page]'} as={'/bookmark/1'}>
            <Typography
              variant="body1"
              component="a"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              樹脂ブックマーク
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={'/browsing_history/[page]'} as={'/browsing_history/1'}>
            <Typography
              variant="body1"
              component="a"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              樹脂閲覧履歴
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem onClick={props.handleLogout}>ログアウト</MenuItem>
      </Menu>
    </div>
  )
}
