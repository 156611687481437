import Cookies from 'js-cookie'
import Router from 'next/router'
import { existPersonalInformation } from '@/api/existPersonalInformation'
import { errorLog } from '@/lib/errorLog'
import errTypes from '@/lib/errTypes'
import { fetcher } from '@/lib/fetcher'
import * as types from '../constants'
import { handleMessageOpen } from './snackbar'

// Log In Action Creators

export const loginSuccess = (userData) => ({
  type: types.LOGIN_SUCCESS_USER,
  userData,
  existPersonalInformation: existPersonalInformation(userData),
})

export const notLoginUser = (message) => ({
  type: types.LOGIN_ERROR_USER,
  message,
})

// Sign Up Action Creators

export const signUpSuccess = (userData) => ({
  type: types.SIGNUP_SUCCESS_USER,
  userData,
  existPersonalInformation: existPersonalInformation(userData),
})

// updateAccountInfo Up Action Creators
export const updateAccountInfoSuccess = (userData) => ({
  type: types.UPDATE_ACCOUNT_INFO_SUCCESS,
  userData,
  existPersonalInformation: existPersonalInformation(userData),
})

export const updateAccountInfoError = (message) => ({
  type: types.UPDATE_ACCOUNT_INFO_ERROR,
  message,
})

export const personalInformationSuccess = (userData) => ({
  type: types.PERSONAL_INFORMATION_SUCCESS,
  userData,
  existPersonalInformation: existPersonalInformation(userData),
})

// delete account

export const confirmUserDelete = (data, successMessage) => (dispatch) => {
  return fetcher
    .post('/v1/user/delete', data)
    .then((response) => {
      if (response.data.err) {
        if (response.data.err.code === errTypes.code.NO_PERMISSION_TO_DELETE_ACCOUNT) {
          dispatch(
            handleMessageOpen(
              errTypes.message[errTypes.code.NO_PERMISSION_TO_DELETE_ACCOUNT],
              5000,
            ),
          )
        }
        if (response.data.err.code === errTypes.code.FAILED_TO_DELETE_ACCOUNT) {
          dispatch(
            handleMessageOpen(
              errTypes.message[errTypes.code.FAILED_TO_DELETE_ACCOUNT],
              'error',
              5000,
            ),
          )
          dispatch(notLoginUser('Oops! Something went wrong!'))
        }
        if (response.data.err.code === errTypes.code.ERROR_IN_ACCOUNT_DELETION) {
          dispatch(
            handleMessageOpen(
              errTypes.message[errTypes.code.ERROR_IN_ACCOUNT_DELETION],
              'error',
              5000,
            ),
          )
        }

        return
      }
      Cookies.remove('token')
      dispatch(logoutSuccess())
      dispatch(handleMessageOpen(successMessage, 'success', 5000))
      Router.push('/')
    })
    .catch((err) => {
      errorLog(err)
      dispatch(handleMessageOpen('入力検証中にエラーが発生しました', 'error', 5000))
    })
}

export const passwordCorrect = () => ({ type: types.PASSWORD_CURRECT })

export const openModalPassword = () => ({ type: types.OPEN_MODAL_PASSWORD })

export const accountDeleteError = () => ({ type: types.ACCOUNT_DELETE_ERROR })

export const accountDeleteReset = () => ({ type: types.ACCOUNT_DELETE_RESET })

export const forgetPasswordReset = () => ({ type: types.FORGET_PASSWORD_RESET })

export const changePasswordReset = () => ({ type: types.CHANGE_PASSWORD_RESET })

export const accountDelete = () => ({ type: types.ACCOUNT_DELETE })

// Log Out Action Creators
const beginLogout = () => ({ type: types.LOGOUT_USER })

const logoutSuccess = () => ({ type: types.LOGOUT_SUCCESS_USER })

export const sessionSuccess = (userData) => ({
  type: types.SESSION_SUCCESS_USER,
  userData,
  existPersonalInformation: existPersonalInformation(userData),
})

export const userLoginMode = () => ({ type: types.USER_LOGIN_MODE })

export const userSignupMode = () => ({ type: types.USER_SIGNUP_MODE })

export const logOut = () => (dispatch) => {
  dispatch(beginLogout())
  Cookies.remove('token')
  dispatch(logoutSuccess())
}

export const openModalLogin = () => ({ type: types.OPEN_MODAL_LOGIN })
export const closeModalLogin = () => ({ type: types.CLOSE_MODAL_LOGIN })
