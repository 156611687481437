import React from 'react'
import { Box, List, ListItem } from '@mui/material'
import { AppDrawerNavListItem } from '../../atoms/AppDrawerNavListItem/AppDrawerNavListItem'

type Props = {
  authenticated: any
}

export const AppDrawerNavList = (props: Props) => (
  <List component="nav">
    {props.authenticated ? null : (
      <AppDrawerNavListItem route="/login" word="ログイン" path="/login" />
    )}
    <AppDrawerNavListItem route="/" word="トップ" path="/" />
    <AppDrawerNavListItem route="/news/page/1" word="ニュース" path="/news/page/[pageNum]" />
    <AppDrawerNavListItem route="/search" word="ニュース検索" path="/search" />
    <AppDrawerNavListItem route="/core" word="樹脂検索" path="/core" />
    <AppDrawerNavListItem route="/spot_rate" word="外国為替公示相場" path="/spot_rate" />
    <AppDrawerNavListItem route="/naphtha" word="ナフサ貿易統計" path="/naphtha" />
    <AppDrawerNavListItem route="/wti" word="WTI原油先物価格" path="/wti" />
    <AppDrawerNavListItem route="/faq" word="よくある質問" path="/faq" />
    <AppDrawerNavListItem route="/contact_us" word="お問い合わせ" path="/contact_us" />
    <AppDrawerNavListItem route="/terms" word="利用規約" path="/terms" />
    <ListItem button={true}>
      <Box
        component="a"
        sx={{
          color: 'black',
          textDecoration: 'none',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          width: 230,
        }}
        href="https://www.kanamorisangyo.co.jp/privacy"
        target="_blank"
        rel="noreferrer"
      >
        プライバシーポリシー
      </Box>
    </ListItem>
    <AppDrawerNavListItem route="/trademark" word="商標について" path="/trademark" />
  </List>
)
