import { GET_SEARCH_FIELD_SUCCESS, FILTER_SEARCH_FIELD, GET_ONE_SEARCH } from '../constants'

const initialState = {
  iso: [],
  product: [],
  maker: [],
  resin: [],
  flame: [],
  selectMakers: [],
  selectResins: [],
  selectProducts: [],
  selectFlames: [],
  selectIsos: [],
  filteredMakers: [],
  filteredResins: [],
  filteredProducts: [],
  filteredFlames: [],
  filteredIsos: [],
  searchFieldLoaded: false,
}

export default function search_field(state = initialState, action) {
  switch (action.type) {
    case GET_SEARCH_FIELD_SUCCESS: {
      return {
        ...state,
        maker: action.search_field.makers,
        resin: action.search_field.resin_names,
        product: action.search_field.product_names,
        flame: action.search_field.flame_ratings,
        iso: action.search_field.iso_names,
        filteredMakers: action.search_field.makers,
        filteredResins: action.search_field.resin_names,
        filteredProducts: action.search_field.product_names,
        filteredFlames: action.search_field.flame_ratings,
        filteredIsos: action.search_field.iso_names,
        searchFieldLoaded: true,
      }
    }

    case FILTER_SEARCH_FIELD: {
      return {
        ...state,
        filteredFlames: action.filterFields.flame,
        filteredIsos: action.filterFields.iso,
        filteredMakers: action.filterFields.maker,
        filteredProducts: action.filterFields.product,
        filteredResins: action.filterFields.resin,
        selectFlames: action.selectFields.flame,
        selectIsos: action.selectFields.iso,
        selectMakers: action.selectFields.maker,
        selectProducts: action.selectFields.product,
        selectResins: action.selectFields.resin,
      }
    }

    case GET_ONE_SEARCH: {
      const query = { ...action.item }
      const { filterSearch } = query
      if (filterSearch) {
        const {
          filteredFlames,
          filteredIsos,
          filteredMakers,
          filteredProducts,
          filteredResins,
          selectFlames,
          selectIsos,
          selectMakers,
          selectProducts,
          selectResins,
        } = filterSearch

        return {
          ...state,
          filteredFlames,
          filteredIsos,
          filteredMakers,
          filteredProducts,
          filteredResins,
          selectFlames,
          selectIsos,
          selectMakers,
          selectProducts,
          selectResins,
        }
      }

      return state
    }
    default: {
      return state
    }
  }
}
