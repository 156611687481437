import { TOGGLE_HEADER, SET_META_TAGS } from '../constants'

const initialState = {
  hideHeader: false,
}

export default function spotrate(state = initialState, action) {
  switch (action.type) {
    case SET_META_TAGS:
      return { ...state, ...action.metaTags }

    case TOGGLE_HEADER:
      return { ...state, hideHeader: action.hideHeader }

    default: {
      return state
    }
  }
}
