import { CLOSE_FULLSCREEN_DIALOG, OPEN_FULLSCREEN_DIALOG } from '../constants'

export const initialState = {
  fullScreenOpen: false,
  fullScreenName: '',
}

export default function dialog(state = initialState, action) {
  switch (action.type) {
    case OPEN_FULLSCREEN_DIALOG: {
      return { ...state, fullScreenOpen: true, fullScreenName: action.name }
    }

    case CLOSE_FULLSCREEN_DIALOG: {
      return { ...state, fullScreenOpen: false, fullScreenName: '' }
    }

    default: {
      return state
    }
  }
}
