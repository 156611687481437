import React from 'react'
import { ListItemButton, ListItemText } from '@mui/material'
import { Link } from '@/components/atoms/Link'

type Props = {
  route: string
  word: string
  path: string
}

export const AppDrawerNavListItem = (props: Props) => (
  <Link href={props.path} as={props.route}>
    <ListItemButton component="a" sx={{ textDecoration: 'none', color: 'black' }}>
      <ListItemText
        primary={props.word}
        sx={{ wordWrap: 'break-word', overflowWrap: 'break-word', width: 230 }}
      />
    </ListItemButton>
  </Link>
)
